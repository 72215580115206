path {
  pointer-events: all;
}
path:hover {
  opacity: 0.50;
  cursor: pointer;
}

.us-state-map {
  width: 100%;
}