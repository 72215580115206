/* Select Sheet Column Styling. */
.spreadsheet-column-select-container > .spreadsheet-column-select-subcontainer > .spreadsheet-column-select-row {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.spreadsheet-column-select-container > .spreadsheet-column-select-subcontainer > .spreadsheet-column-select-row > div {
  width: 47%;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1em;
  margin-right: 0.10em;
}

.spreadsheet-column-select-container > .spreadsheet-column-select-subcontainer > .spreadsheet-column-select-row > div > p {
  width: 100%;
  font-size: 1em;
}

.spreadsheet-column-select-container > .spreadsheet-column-select-subcontainer > .spreadsheet-column-select-row > div > .ant-select {
  width: 100%;
}

.spreadsheet-column-select-container > .spreadsheet-column-select-subcontainer > .spreadsheet-column-select-row > div > div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.spreadsheet-column-select-container > .spreadsheet-column-select-subcontainer > .spreadsheet-column-select-row > div > div > input[type="checkbox"] {
  margin-top: -1.5em;
}
