.Home .lander {
  padding: 40px 0;
  text-align: center;
  display: inline-block;
}

.Home .lander h1 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.Home .salesDiv {
  display: inline-block;
  float: left;
}
.Home .salesDiv h1 {
  text-align: center;
}
.Home .licensesDiv {
  display: inline-block;
  float: right;
}

#appList > * > .appList-disabledImage {
  filter: grayscale(100%);
}

.Home .secondLander {
  display: inline-block;
  padding: 40px 0;
}

.iconSizeApp {
  width: 120px;
  height: auto;
}

.navbar-text {
  font-size: 0.8em;
  color: #777;
}

body {
  color: #212529;
}
#root {
  height: 100vh;
  background-color: #f8f9fa;
}

/* Placeholder */
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #8d9096;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #8d9096;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #8d9096;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8d9096;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8d9096;
}
​ ::placeholder {
  /* Most modern browsers support this now. */
  color: #8d9096;
}

.form-label {
  color: #000;
  font-weight: 600;
  text-align: center;
}

.form-group {
  margin: 0.2em !important;
}

input.form-control {
  color: #000000;
}

.iap-header {
  color: #333;
  font-weight: 300; //Changed from 800
  font-size: 48px;
}

.iap-header img {
  width: 50px;
  height: auto;
}

.iap-slogan {
  font-size: 29px;
  color: #333;
  font-family: 'Caveat', cursive;
}

.iap-header-icon {
  width: 40px;
  height: auto;
}

@import url('https://fonts.googleapis.com/css?family=Caveat&display=swap');

.cool-link {
  display: inline-block;
  color: #777;
  text-decoration: none;
  margin-bottom: 10px;
}
.cool-link::after {
  content: '';
  display: block;
  width: 0;
  height: 5px;
  transition: width 0.3s;
}
.cool-link:hover::after {
  width: 100%;
}
.cool-link img:hover {
  opacity: 0.9;
}
.app-name {
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: #777;
}

#app-Admin::after {
  background: #8989ff;
}
#app-Data::after {
  background: #ff6347;
}
#app-Licensing::after {
  background: #43cd80;
}
#app-Marketing::after {
  background: #f95770;
}
#app-Operations::after {
  background: #826a51;
}
#app-Sales::after {
  background: #00c5cd;
}
#app-DTC:after{
  background: #3773CA;
}
#app-Compliance::after{
  background: #ff6245;
}
.svgHeader {
  pointer-events: none;
}
.logoutText,
.wave-text {
  color: #777 !important;
  font-size: 0.9rem;
}

.wave-footer {
  display: block;
  width: 100%;
  height: 60px;
  max-height: 60px;
  margin: 0;
  z-index: 5;
  bottom: 0;
  position: absolute;
  left: 0px;
  float: left;
}
.parallax1 > use {
  animation: move-forever1 10s linear infinite;
  &:nth-child(1) {
    animation-delay: -2s;
  }
}
.parallax2 > use {
  animation: move-forever2 8s linear infinite;
  &:nth-child(1) {
    animation-delay: -2s;
  }
}
.parallax3 > use {
  animation: move-forever3 6s linear infinite;
  &:nth-child(1) {
    animation-delay: -2s;
  }
}
.parallax4 > use {
  animation: move-forever4 4s linear infinite;
  &:nth-child(1) {
    animation-delay: -2s;
  }
}
@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}
@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@keyframes move-forever3 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}
@keyframes move-forever4 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
