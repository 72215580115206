.LicensingDisplay {
  
  @media (max-width: 768px){
    #LeftColumn{
      text-align: left;
    }
  }

  @media (min-width: 768px){
    #LeftColumn{
      text-align: right;
    }
  }

  .nav{
    margin-bottom: 2px;
  }

  #LeftColumn{
    margin-top: .65rem;
  }

  #saveButtonContainer {
    text-align: center;
  }

  .cleaveInput{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    font-size: 16px;
    color: #000000;
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  }

  .cleaveInput:disabled{
    background-color:#e9ecef;
  }

  .selectInput{
    display: block;
    width: 100%;
    padding: .175rem 1.05rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border-radius: .25rem;
    font-size: 16px;
    color: #000000
  }

  .selectInput:disabled{
    background-color:#e9ecef;
  }
}