#edit-commission-form {
  & > .ant-row.ant-form-item.col {
    display: flex;
    justify-content: space-between;
    width: 100%;
    & > .ant-form-item-control {
      margin-left: 2%;
      & > .ant-form-item-control-input {
        & > .ant-form-item-control-input-content {
          display: flex;
          justify-content: space-between;
          & > input {
            width: 40%;
          }

          & > .ant-btn.ant-dropdown-trigger {
            width: 40%;
          }

          & > button {
            border-radius: 5px;
            padding: 3px 10px;
          }
        }
      }
    }
  }
}
